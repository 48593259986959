import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Amplify } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useApp } from 'src/contexts/AppContext';
import { useDashboard } from 'src/contexts/DashboardContext';

const TermsOfService = ({ children }) => {
  return <>{children}</>;
  const { config, cognito } = useApp();
  const { dashboard } = useDashboard();
  const [checked, setChecked] = useState(false);
  const [tos, setTos] = useState(null);
  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(null);
  const { t } = useTranslation();

  const userVersion = cognito.attributes['custom:tos_version'];
  const latestVersion = config.tosVersion;

  useEffect(() => {
    const isLatest = userVersion === latestVersion;

    if (!isLatest) {
      Amplify.Storage.get(`tos/versions/${latestVersion}.htm`, { level: 'public' })
        .then((result: string) => setTos(result));
    }

    setOpen(!isLatest);
    setConfirmed(isLatest);
  }, [userVersion, latestVersion]);

  const handleCheckboxChange = () => setChecked(!checked);

  const handleConfirmClick = () => {
    if (!checked) return;
    cognito.updateAttributes([{ Name: 'custom:tos_version', Value: latestVersion }], () => {
      setOpen(!checked);
      setConfirmed(checked);
    });
  };

  const handleClose = (_: any, reason: string) => {
    // if the user clicks outside the dialog, don't close it
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;

    cognito.signOut();
  };

  if (confirmed === null) return;

  return (
    <>
      {confirmed && children}
      {!confirmed && dashboard && (
        <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose} disableEscapeKeyDown={true}>
          <DialogTitle sx={{ fontWeight: 'bold' }}>
            {t('termsOfService.title')} v{latestVersion}
          </DialogTitle>
          <DialogContent>
            <Grid container item direction="column">
              <Grid item maxHeight="500px" height={{ md: '500px', sm: '300px' }}>
                {tos && <iframe src={tos} height="100%" width="100%" />}
              </Grid>
              <Grid item>
                <p style={{ fontWeight: 'bold' }}> {t('termsOfService.desc')}</p>
                <label>
                  <input type="checkbox" checked={checked} onChange={handleCheckboxChange} />
                  {t('termsOfService.checkbox')}
                </label>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cognito.signOut()}>{t('label.decline')}</Button>
            <Button onClick={handleConfirmClick} disabled={!checked} variant="contained" color="primary">
              {t('label.accept')}
            </Button>
          </DialogActions>
        </Dialog>)
      }
    </>
  );
};

export default TermsOfService;
