import { Storage } from 'aws-amplify';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ImageType } from 'src/models';

type s3ImageProps = {
  category: ImageType;
  fileKey: string;
  download?: boolean;
};

export const getS3Image = async (
  fileKey: string,
  level: 'public' | 'private',
  download: boolean,
  setState?: Dispatch<SetStateAction<string>>
): Promise<string | null> => {
  if (!fileKey) return null;

  let data = null;

  try {
    const result = await Storage.get(fileKey, { level, download });
    if (download) {
      const blob = (result as unknown as { Body: any })?.Body;
      if (blob) data = URL.createObjectURL(blob);
    } else {
      data = result;
    }
  } catch (err) {
    console.error(`Failed to get img: ${fileKey}`, err);
    return null;
  }

  if (setState) setState(data);
  return data;
};

const useS3Image = ({ category, fileKey, download = true }: s3ImageProps) => {
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    const loadImage = async () => {
      if (!category || !fileKey) return setImage(null);

      if (category === ImageType.avatar) await getS3Image(fileKey, 'private', download, setImage);
      else await getS3Image(`${category}/${fileKey}`, 'public', download, setImage);
    };

    loadImage();
  }, [category, fileKey, download]);

  return image;
};

export default useS3Image;
