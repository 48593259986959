import SettingsIcon from '@mui/icons-material/AccountTreeTwoTone';
import SignOutIcon from '@mui/icons-material/LockOpenTwoTone';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Avatar from 'src/components/Avatar';
import { useUser } from 'src/contexts/UserContext';
import useS3Image from 'src/hooks/ImageLoaderHook';
import { ImageType } from 'src/models';

const UserBoxHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  position: 'relative',
  '&::after': {
    content: '" "',
    width: '80%',
    height: '1px',
    position: 'absolute',
    bottom: 0,
    left: '10%', // center
    borderBottom: `1px solid ${theme.palette.secondary.dark}`
  }
}));

const UserBoxText = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  paddingLeft: theme.spacing(1)
}));

const UserBoxListItem = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  '&:hover': {
    background: 'inherit',
    color: theme.palette.secondary.main
  }
}));

const UserBoxListTextStyle = { color: 'inherit', fontWeight: 700 };

function HeaderUserBox() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const { user, access } = useUser();
  const avatar = useS3Image({
    category: ImageType.avatar,
    fileKey: user.picture
  });
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);

  const to = (path: string): void => {
    handleClose();
    navigate(path);
  };

  const signOut = (): void => {
    handleClose();
    Auth.signOut();
  };

  return (
    <>
      <IconButton ref={ref} sx={{ height: theme.header.height }} onClick={handleOpen}>
        <Avatar user={user} image={avatar} />
      </IconButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
            backdropFilter: 'none'
          }
        }}
      >
        <Box
          sx={{
            width: 300,
            background: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }}
        >
          <UserBoxHeader>
            <UserBoxText>
              <Typography variant="h5" color="inherit">
                {user.givenName} {user.familyName}
              </Typography>
              <Typography variant="body1" color="inherit">
                {user.companyName}
              </Typography>
              <Typography variant="body1" color="inherit">
                {access.asTenant?.toHumanReadable()}
              </Typography>
            </UserBoxText>
          </UserBoxHeader>
          <List
            sx={{ p: 1, background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
            component="nav"
          >
            <UserBoxListItem onClick={() => to('/profile/settings')}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={UserBoxListTextStyle}
                primary={t('label.accountSettings')}
              />
            </UserBoxListItem>
          </List>
          <Box p={1} display="flex" flexDirection="row" gap={1} justifyContent="flex-end">
            {access.isStaff && (
              <Button variant="accent-outlined" size="small" onClick={() => to('/profile/switchrole')}>
                Switch role
              </Button>
            )}
            <Button variant="accent" size="small" onClick={signOut} startIcon={<SignOutIcon />}>
              Sign out
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserBox;
