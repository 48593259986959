/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getMenuItems = /* GraphQL */ `query GetMenuItems {
  getMenuItems {
    partner
    customer
    businessUnit
    assets
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuItemsQueryVariables,
  APITypes.GetMenuItemsQuery
>;
export const getAssets = /* GraphQL */ `query GetAssets {
  getAssets {
    id
    businessUnit
    customer
    partner
    state
    name
    type
    overviews {
      asset
      fileKey
      type
      ext
      relation
      __typename
    }
    isWatermelon
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAssetsQueryVariables, APITypes.GetAssetsQuery>;
export const getBusinessUnits = /* GraphQL */ `query GetBusinessUnits {
  getBusinessUnits {
    id
    customer
    partner
    state
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBusinessUnitsQueryVariables,
  APITypes.GetBusinessUnitsQuery
>;
export const getCustomers = /* GraphQL */ `query GetCustomers {
  getCustomers {
    id
    partner
    state
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomersQueryVariables,
  APITypes.GetCustomersQuery
>;
export const getPartners = /* GraphQL */ `query GetPartners {
  getPartners {
    id
    state
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPartnersQueryVariables,
  APITypes.GetPartnersQuery
>;
export const getDashboardItems = /* GraphQL */ `query GetDashboardItems($asset: ID!) {
  getDashboardItems(asset: $asset) {
    controlUnit {
      id
      devEUI
      location {
        controlUnit
        fileKey
        type
        ext
        relation
        coord {
          id
          x
          y
          __typename
        }
        __typename
      }
      version
      installationDate
      isOnline
      __typename
    }
    strips {
      id
      controlUnit
      config {
        algoType
        lowerBoundary
        upperBoundary
        __typename
      }
      image {
        strip
        fileKey
        type
        ext
        coords {
          id
          x
          y
          __typename
        }
        __typename
      }
      pipeImage {
        strip
        fileKey
        type
        ext
        info {
          mountedType
          isReversed
          startAngle
          radius
          __typename
        }
        __typename
      }
      location
      stripType
      installationDate
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardItemsQueryVariables,
  APITypes.GetDashboardItemsQuery
>;
export const getDashboardConfig = /* GraphQL */ `query GetDashboardConfig {
  getDashboardConfig {
    tosVersion
    isMaintenance
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardConfigQueryVariables,
  APITypes.GetDashboardConfigQuery
>;
export const getReports = /* GraphQL */ `query GetReports($type: String!, $id: ID!) {
  getReports(type: $type, id: $id) {
    ... on AnalysisReport {
      id
      timestamp
      value
      activity
      delayedTimestamp
      delayedValue
      delayedActivity
      connectivity {
        daily
        weekly
        __typename
      }
    }
    ... on StripReport {
      id
      range {
        from
        to
        __typename
      }
      value
      activity
      delayedRange {
        from
        to
        __typename
      }
      delayedValue
      delayedActivity
      connectivity {
        daily
        weekly
        __typename
      }
    }
    ... on ControlUnitReport {
      id
      range {
        from
        to
        __typename
      }
      value
      activity
      delayedRange {
        from
        to
        __typename
      }
      delayedValue
      delayedActivity
      connectivity {
        daily
        weekly
        __typename
      }
      voltage
      battery
      lifetime
    }
    ... on AssetReport {
      id
      range {
        from
        to
        __typename
      }
      value
      activity
      delayedRange {
        from
        to
        __typename
      }
      delayedValue
      delayedActivity
      connectivity {
        daily
        weekly
        __typename
      }
      stat {
        NO
        MODERATE
        SIGNIFICANT
        PENDING
        totalStrips
        totalControlUnits
        __typename
      }
    }
  }
}
` as GeneratedQuery<
  APITypes.GetReportsQueryVariables,
  APITypes.GetReportsQuery
>;
export const getHistory = /* GraphQL */ `query GetHistory(
  $controlUnit: ID!
  $strip: ID!
  $type: String!
  $from: AWSDateTime!
  $to: AWSDateTime!
) {
  getHistory(
    controlUnit: $controlUnit
    strip: $strip
    type: $type
    from: $from
    to: $to
  ) {
    controlUnit
    strip
    zone
    points {
      datetime
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHistoryQueryVariables,
  APITypes.GetHistoryQuery
>;
