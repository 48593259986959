import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import PageHeader from 'src/components/PageHeader';
import { Card, CardContent, Grid } from '@mui/material';
import { Storage } from 'aws-amplify';
import { useEffect, useState } from 'react';

import DownloadButton from 'src/components/DownloadButton';

export type Downloadable = {
  filekey: string;
  filename: string;
};

const Download = () => {
  const [downloadables, setDownloadables] = useState<Downloadable[]>([]);

  const fetchFiles = async (files: string) => {
    if (!files) return;
    try {
      return await Storage.list(files, { level: 'public' });
    } catch (err) {
      console.error(`Failed to get files: ${files}`, err);
    }
  };

  useEffect(() => {
    fetchFiles('downloads/').then((items) => {
      let documents = items.results.filter((file) => file.size != 0);
      if (documents.length != downloadables.length) {
        setDownloadables(() =>
          documents.map((document) => {
            return { filekey: document.key, filename: document.key.extractFilename() };
          })
        );
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('label.download')}</title>
      </Helmet>
      <PageHeader title={t('page.download.title')} subtitle={t('page.download.desc')} />
      <Grid container padding={4}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                {downloadables.map((download) => (
                  <Grid item xs={12} key={download.filekey}>
                    <DownloadButton downloadable={download} />
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Download;
