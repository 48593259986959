declare global {
  interface Date {
    isOnline(): boolean;
    toLocalTimezoneOffset(): string;
  }

  interface String {
    toCapitalCase(): string;
    toHumanReadable(): string;
    toColor(): string;
    extractExtension(): string;
    extractFilename(): string;
  }
}

//=====================================================================================================================
// DATE EXTENSION
//=====================================================================================================================
{
  /**
   * Checks if the date is within 48 hours from the current time.
   *
   * @returns {boolean} True if the date is within 48 hours, false otherwise.
   */
  Date.prototype.isOnline = function (): boolean {
    return (new Date().getTime() - this.getTime()) / (1000 * 60 * 60) <= 48;
  };

  /**
   * Gets the local presentation of difference between Universal Coordinated Time (UTC) and the time on the local computer in format:
   * City (UTC +/- XX:XX)
   *
   * @returns {string} String, like Europe/Berlin (UTC+02:00).
   */
  Date.prototype.toLocalTimezoneOffset = function (): string {
    const offset = this.getTimezoneOffset();
    return `${Intl.DateTimeFormat().resolvedOptions().timeZone} (UTC${offset > 0 ? '-' : '+'}${Math.abs(
      Math.floor(offset / 60)
    )
      .toString()
      .padStart(2, '0')}:${Math.abs(Math.floor(offset % 60))
      .toString()
      .padStart(2, '0')})`;
  };
}

//=====================================================================================================================
// STRING EXTENSIONS
//=====================================================================================================================
{
  /**
   * Capitalizes the first letter of the string and converts the rest of the string to lowercase.
   * @returns {string} A new string with the first letter capitalized and the rest in lowercase.
   */
  String.prototype.toCapitalCase = function (): string {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
  };

  /**
   * Replaces underscores with whitespaces, capitalizes the first letter of the string and converts the rest of the string to lowercase.
   * @returns {string} A new string with the first letter capitalized and the rest in lowercase with underscores replaced by whitespaces.
   */
  String.prototype.toHumanReadable = function (): string {
    return this.replace('_', ' ').toCapitalCase();
  };

  /**
   * Generates a hex color string from original string as seed.
   * @returns {string} A new hex color generated from original string. Empty string results in white ('#FFF').
   */
  String.prototype.toColor = function (): string {
    if (!this) return '#FFF';

    let hash = 0;

    /* eslint-disable no-bitwise */
    for (let i = 0; i < this.length; i += 1) {
      hash = this.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  /**
   * Extract filename from string of format: "/filename.extension" (result: "filename").
   * @returns {string} A new string that is a name of file if string is a filename.
   */
  String.prototype.extractFilename = function (): string {
    return this.substring(this.indexOf('/') + 1, this.indexOf('.'));
  };

  /**
   * Extract extension from string of format: "/filename.extension" (result: "extension").
   * @returns {string} A new string that is an extension of file if string is a filename.
   */
  String.prototype.extractExtension = function (): string {
    return this.split('.').pop();
  };
}

export {};
